<template>
  <teleport to="html">
    <ModalBackdrop>
      <div
        class="fixed top-0 left-0 right-0 z-50 w-full p-2 overflow-x-hidden overflow-y-auto h-[calc(100%-1rem)] flex justify-center items-center"
        v-if="notification.loading"
      >
        <div class="relative w-full max-w-sm max-h-[20rem] m-auto">
          <div class="relative bg-white rounded-lg shadow p-4">
            <div class="text-center">
              <Spinner :width="16" :height="16" />
              <p class="text-sm">Loading...</p>
            </div>
          </div>
        </div>
      </div>

      <ModalContainer v-else>
        <ModalBody>
          <FlexCol class="gap-y-3 items-center">
            <div class="text-mulah-red">
              <IconExclamation class="w-20 h-20" />
            </div>
            <h1 class="font-poppinsBold text-3xl text-center" v-html="popupTitle"></h1>
            <p class="text-lg text-center" v-html="popupBody"></p>
            <Button variant="mulah" size="sm" class="w-full" @click="handleClose()"> Okay </Button>
          </FlexCol>
        </ModalBody>
      </ModalContainer>
    </ModalBackdrop>
  </teleport>
</template>
<script setup>
import ModalBackdrop from '@/components/uielements/Modal/ModalBackdrop.vue'
import ModalContainer from '@/components/uielements/Modal/ModalContainer.vue'
import ModalBody from '@/components/uielements/Modal/ModalBody.vue'
import Button from '@/components/uielements/Button/Button.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import IconExclamation from '@/components/icons/IconExclamation.vue'
import Spinner from '@/components/reusable/Spinner.vue'
import { computed } from 'vue'
import { useBrandStore, useNotificationConfig } from '@/stores/store'

const store = useBrandStore()
const notification = useNotificationConfig()

const props = defineProps({
  specification: {
    type: String,
    required: true
  }
})

const popupTitle = computed(
  () =>
    notification.notificationConfig.find((item) => item.specification === props.specification)
      ?.popupTitle
)

const popupBody = computed(() => {
  const config = notification.notificationConfig.find(
    (item) => item.specification === props.specification
  )

  const data = {
    brand_name: store.brandName
  }
  return replacePlaceholders(config.popupBody, data)
})

function replacePlaceholders(text, data) {
  return text.replace(/{(.*?)}/g, (_, key) => data[key] || '')
}

const emit = defineEmits(['close-modal'])

function handleClose() {
  emit('close-modal')
}
</script>
