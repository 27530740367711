<template>
  <FlexRow class="justify-center" v-if="isLoading">
    <FlexCol class="grid h-screen place-items-center">
      <Spinner :width="16" :height="16" class="-mt-56" />
    </FlexCol>
  </FlexRow>
  <FlexCol class="container min-h-[80vh] overflow-y-auto mb-20" v-else>
    <GooglePoster
      v-if="[1, 2, 3].includes(step)"
      :promotion-name="promotionName"
      :image-url="imageUrl"
    />
    <SelectStore
      v-if="step === 1"
      :enabled-outlets="enabledOutlets"
      :reviewed-outlets="reviewedOutlets"
      @outlet-selected="handleSelectedOutlet"
    />
    <PostReview
      v-if="step === 2"
      :review-info="reviewInfo"
      :brand-name="brandName"
      :currency-code="currencyCode"
      :show-leave-review-component="showLeaveReviewComponent"
      :validated-number-and-spending="validatedNumberAndSpending"
      @timer-ended="handleTimerEnd"
      @start-writing-clicked="handleStartWritingClicked"
      @leave-review-clicked="triggerVerifyReview"
    />
    <EnterPhoneNo
      v-if="showPhoneInputModal"
      :currency-code="currencyCode"
      :min-spend="reviewInfo.minSpend"
      @valid-number-updated="updateValidNumber"
      @go-back="showPhoneInputModal = false"
    />
    <NotificationPage
      :notification-type="notificationType"
      :min-spend="reviewInfo.minSpend"
      :promotion-name="promotionName"
      :brand-name="brandName"
      :campaignName="campaignName"
      :enabled-outlet-count="enabledOutletCount"
      :currency-code="currencyCode"
      :selected-outlet="reviewInfo.outletName"
      :unique-hashtag="reviewInfo.uniqueHashtag"
      :selected-hashtags="reviewInfo.selectedHashtags"
      @timer-ended="handleTimerEnd"
      @retry-redeem="handleRetryRedeem"
      v-if="step === 4"
      @go-to-step="goToStep"
    />
    <LimitReached :specification="specification" v-if="step === 5" />
    <QualifiedModal
      v-if="showQualifiedModal"
      @ok="
        () => {
          showLeaveReviewComponent = true
          showQualifiedModal = false
        }
      "
    />
    <NotQualifiedModal
      v-if="showNotQualifiedModal"
      :currency-code="currencyCode"
      :min-spend="reviewInfo.minSpend"
      :outlet-name="reviewInfo.outletName"
      @go-back="showNotQualifiedModal = false"
      @done="validateSpending"
    />

    <div class="flex items-center mb-2" v-if="isFetching">
      <span class="text-sm me-0.5">Loading</span>
      <span class="dot-flashing ms-5"></span>
    </div>

    <OptionButtonBar
      class="mb-10 text-gray mt-5"
      v-if="step !== 4 && step !== 5"
      :btn-next-title="'Continue'"
      @btn-next-func="nextStep()"
      @btn-prev-func="prevStep()"
      :btn-next-disabled="disableNextButton()"
      :btn-prev-disabled="disablePrevButton()"
    />
  </FlexCol>
  <MobileFooter class="-mx-5" />
</template>

<script setup>
import { onMounted, watch, ref, computed } from 'vue'
import cookie from '@/utils/cookie.js'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'

// external components
import MobileFooter from '@/layout/MobileFooter/Index.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import OptionButtonBar from '@/pages/GoogleReview/Components/OptionButtonBar.vue'
import Spinner from '@/components/reusable/Spinner.vue'

// internal components
import GooglePoster from './GooglePoster.vue'
import SelectStore from './SelectStore.vue'
import PostReview from './PostReview.vue'
import EnterPhoneNo from './EnterPhoneNo.vue'
import NotificationPage from './NotificationPage.vue'
import QualifiedModal from '@/pages/GoogleReview/Components/QualifiedModal.vue'
import NotQualifiedModal from '@/pages/GoogleReview/Components/NotQualifiedModal.vue'
import LimitReached from '@/pages/LimitReached/Index.vue'

// urql
import {
  GENERATE_REVIEW_SESSION,
  VALIDATE_REVIEW_SPEND,
  UPDATE_TOTAL_SCANS,
  GET_REVIEW_CAMPAIGN,
  GET_BRAND_SLUG,
  EXPIRE_REVIEW_SESSION,
  TRIGGER_REVIEW_VERIFICATION
} from '@/constants/graphql.js'
import { useMutation, useQuery } from '@urql/vue'

const { executeMutation: generateReviewSession, fetching: generateReviewSessionFetching } =
  useMutation(GENERATE_REVIEW_SESSION)
const { executeMutation: validateReviewSpend, fetching: validateReviewSpendFetching } =
  useMutation(VALIDATE_REVIEW_SPEND)
const { executeMutation: updateTotalScans, fetching: updateTotalScansFetching } =
  useMutation(UPDATE_TOTAL_SCANS)
const { executeMutation: expireReviewSession } = useMutation(EXPIRE_REVIEW_SESSION)
const { executeMutation: triggerReviewVerification } = useMutation(TRIGGER_REVIEW_VERIFICATION)

const brandName = ref('')
const step = ref(1)
const campaignName = ref('')
const campaignExpiry = ref(null)
const imageUrl = ref(null)
const campaignId = ref(null)
const currencyCode = ref(null)
const promotionName = ref('')
const selectedOutletId = ref(null)
const notificationType = ref('')
const isLoading = ref(true)
const showQualifiedModal = ref(false)
const showNotQualifiedModal = ref(false)
const showPhoneInputModal = ref(false)
const showLeaveReviewComponent = ref(false)
const leaveReviewClicked = ref(false)
const loadingValidSpending = ref(false)
const loadingVerifyMutation = ref(false)
const validatedNumberAndSpending = ref(false)
const specification = ref(null)

const route = useRoute()
const brandSlug = ref(route.params.slug)
const reviewId = ref(route.params.reviewId)
const pause = ref(true)

/* CHECK BRAND SLUG EXIST */
const currencySymbol = {
  MY: 'RM',
  ID: 'Rp',
  SG: 'SGD'
}
const brandSlugQuery = useQuery({
  query: GET_BRAND_SLUG,
  variables: {
    phoneNumber: '',
    sort: 'cost',
    direction: 'ASC',
    filters: {
      status: 'active',
      title: '',
      specification: ''
    },
    slug: brandSlug
  }
})

watch(brandSlugQuery.fetching, async (fetchStatus) => {
  if (!fetchStatus) {
    if (brandSlugQuery.data.value.slBrandWithSlug === null) {
      pause.value = true
      notificationType.value = 'INVALID_SLUG'
      step.value = 4
      isLoading.value = false
    } else {
      if (brandSlugQuery.data.value.slBrandWithSlug.countryCode) {
        const countryCode = brandSlugQuery.data.value.slBrandWithSlug.countryCode
        const symbol = currencySymbol[countryCode] || ''
        currencyCode.value = symbol
      }

      brandName.value = brandSlugQuery.data.value.slBrandWithSlug.name
      pause.value = false
    }
  }
})

/* SELECT STORE */
const enabledOutlets = ref([])
const enabledOutletCount = computed(() => enabledOutlets.value.length)

const handleSelectedOutlet = (outlet) => {
  selectedOutletId.value = outlet.id

  const selectedOutlet = enabledOutlets.value.find((o) => o.outletId === outlet.id)
  if (selectedOutlet) {
    reviewInfo.value.placeId = selectedOutlet.placeId
    reviewInfo.value.outletName = selectedOutlet.outletName
  }
}
/* SELECT STORE */

/* POST REVIEW */
function handleTimerEnd() {
  showPhoneInputModal.value = false
  showNotQualifiedModal.value = false
  showQualifiedModal.value = false
  step.value = 4
  notificationType.value = 'EXPIRED'
  expireReviewSession({
    slug: brandSlug.value,
    uniqueHashtag: reviewInfo.value.uniqueHashtag
  })
    .then((result) => {})
    .catch((e) => alert(e))
}

const reviewInfo = ref({
  minSpend: 0,
  selectedHashtags: null,
  uniqueHashtag: null,
  placeId: null,
  outletName: null
})

const reviewedOutlets = ref(null)

function genReviewSesh() {
  generateReviewSession({
    slug: brandSlug.value,
    outletId: parseInt(selectedOutletId.value),
    campaignId: campaignId.value
  })
    .then((result) => {
      const data = result.data.slGenerateReviewSession
      const defaultHashtags = data.defaultHashtags ?? []
      const customHashtags = data.customHashtags ?? []
      reviewInfo.value.uniqueHashtag = data.uniqueHashtag
      reviewInfo.value.selectedHashtags = [...defaultHashtags, ...customHashtags].join(' ')
      step.value++
    })
    .catch((e) => alert(e))
}
/* POST REVIEW */

/* COOKIE */
const initCookie = () => {
  const name = `sl_greview_cookie_${campaignId.value}`
  const value = 1
  const maxAge = 86400 // 1 day in seconds

  cookie.setCookie(name, value, maxAge)
}

const initPhoneNumCookie = () => {
  const name = 'gRevPNum'
  const value = {
    cc: selectedCountryCode.value,
    pNum: phoneNumberInput.value
  }
  const maxAge = 86400 // 1 day in seconds

  cookie.setCookie(name, JSON.stringify(value), maxAge)
}

watch(step, (newValue) => {
  if (newValue === 1) {
    initCookie()
    selectedOutletId.value = null
  }
})

function updateTotScans() {
  updateTotalScans({
    campaignId: campaignId.value
  })
    .then((result) => {})
    .catch((e) => alert(e))
}
/* COOKIE */

/* QUERY */
const reviewCampaignQuery = useQuery({
  query: GET_REVIEW_CAMPAIGN,
  variables: {
    slug: brandSlug.value,
    reviewId: encodeURIComponent(reviewId.value)
  },
  pause: pause
})

watch(reviewCampaignQuery.fetching, (fetchResult) => {
  if (!fetchResult) {
    if (reviewCampaignQuery.data.value.slReviewCampaign.campaignData === null) {
      notificationType.value = 'NO_CAMPAIGN'
      step.value = 4
      isLoading.value = false
    } else {
      const campaignData = reviewCampaignQuery.data.value.slReviewCampaign.campaignData
      reviewInfo.value.minSpend = campaignData.minSpend ?? 0
      promotionName.value = campaignData.promotionName
      campaignName.value = campaignData.title
      campaignExpiry.value = campaignData.endTiming
      imageUrl.value = campaignData.imageUrl
      campaignId.value = campaignData.campaignId

      if (step.value === 1) {
        const cookieName = `sl_greview_cookie_${campaignId.value}`
        if (!cookie.getCookie(cookieName)) {
          updateTotScans()
        }
        initCookie()
      }

      campaignData.enabledOutlets.forEach((outlet) => {
        let outletId = outlet.id
        let outletName = outlet.name
        let placeId = outlet.placeId

        enabledOutlets.value.push({ outletId, outletName, placeId })
      })

      if (enabledOutletCount.value === 0) {
        step.value = 4
        notificationType.value = 'CAMPAIGN_EXPIRED'
      }

      reviewedOutlets.value = reviewCampaignQuery.data.value.slReviewCampaign.redeemedOutlets

      checkCampaignExpiry()

      if (enabledOutlets.value.length === 1 && notificationType.value !== 'CAMPAIGN_EXPIRED') {
        selectedOutletId.value = enabledOutlets.value[0].outletId

        reviewInfo.value.placeId = enabledOutlets.value[0].placeId
        reviewInfo.value.outletName = enabledOutlets.value[0].outletName

        genReviewSesh()
      }

      checkCampaignExpiry()

      isLoading.value = false
    }
  }
})
/* QUERY */

/* NAVIGATE STEPS */
const isFetching = computed(() => {
  return (
    brandSlugQuery.fetching.value ||
    reviewCampaignQuery.fetching.value ||
    generateReviewSessionFetching.value ||
    validateReviewSpendFetching.value ||
    updateTotalScansFetching.value
  )
})

const goBack = () => {
  showPhoneInputModal.value = false
}

const handleStartWritingClicked = () => {
  showPhoneInputModal.value = true
}

function nextStep() {
  if (step.value === 1) {
    genReviewSesh()
  } else {
    step.value = 4
  }
}

function prevStep() {
  if (step.value > 1) {
    step.value--
    showLeaveReviewComponent.value = false
    validatedNumberAndSpending.value = false
  }
}

function disableNextButton() {
  switch (step.value) {
    case 1:
      return selectedOutletId.value === null || isFetching.value
    case 2:
      return !validNumber.value || isFetching.value || !leaveReviewClicked.value
    default:
      return isFetching.value
  }
}

function disablePrevButton() {
  return step.value === 1
}

function goToStep(stepNumber) {
  if (stepNumber === 'reward-page') {
    initPhoneNumCookie()
    router.push({
      path: '/' + brandSlug.value
    })
  } else {
    if (stepNumber === '1') {
      selectedOutletId.value = null
    }
    step.value = parseInt(stepNumber)
  }
}
/* NAVIGATE STEPS */

/* ENTER PHONE NO */
const validNumber = ref(false)
const selectedCountryCode = ref(null)
const phoneNumberInput = ref(null)

function updateValidNumber(newValue) {
  validNumber.value = newValue[0]
  selectedCountryCode.value = newValue[1]
  phoneNumberInput.value = newValue[2]

  validateSpending()
}

function validateSpending() {
  if (loadingValidSpending.value) return
  loadingValidSpending.value = true

  validateReviewSpend({
    slug: brandSlug.value,
    phoneNumber: phoneNumberInput.value,
    countryCode: selectedCountryCode.value,
    uniqueHashtag: reviewInfo.value.uniqueHashtag,
    campaignId: campaignId.value,
    outletId: parseInt(selectedOutletId.value)
  })
    .then((result) => {
      const res = result.data.slValidateReviewSpend.result
      if (res === 'OK') {
        validatedNumberAndSpending.value = true
        showPhoneInputModal.value = false
        showNotQualifiedModal.value = false
        showQualifiedModal.value = true
        notificationType.value = 'OK'
      } else if (res === 'NO_MIN_SPEND') {
        showPhoneInputModal.value = false
        showNotQualifiedModal.value = true
      } else if (res === 'PRIZE_CLAIMED') {
        handleServerResponse('PRIZE_CLAIMED')
      } else if (res === 'ALREADY_VALIDATED') {
        alert('This session has already been validated')
      } else if (res === 'EXPIRED') {
        handleServerResponse('EXPIRED')
      } else if (res === 'ALREADY_REDEEMED') {
        handleServerResponse('ALREADY_REDEEMED')
      } else if (res === 'ALREADY_QUEUED') {
        alert('Session is already in verification queue')
      } else {
        alert('Failed to validate your number')
      }
    })
    .catch((e) => alert(e))
    .finally(() => {
      loadingValidSpending.value = false
    })
}

const handleServerResponse = (message) => {
  showPhoneInputModal.value = false
  notificationType.value = message
  step.value = 4
}

const triggerVerifyReview = async () => {
  if (loadingVerifyMutation.value) return
  loadingVerifyMutation.value = true

  const args = {
    slug: brandSlug.value,
    outletId: parseInt(selectedOutletId.value),
    campaignId: campaignId.value,
    uniqueHashtag: reviewInfo.value.uniqueHashtag
  }

  try {
    const response = await triggerReviewVerification(args)

    const res = response.data.slTriggerReviewVerification.result

    if (res === 'OK') {
      leaveReviewClicked.value = true
    } else if (res === 'EXPIRED') {
      notificationType.value = 'EXPIRED'
      step.value = 4
    } else if (res === 'MESSAGE_LIMIT_REACHED') {
      specification.value = 'app_free_campaign'
      step.value = 5
    } else if (res === 'INSUFFICIENT_CREDITS') {
      specification.value = 'app_paid_campaign'
      step.value = 5
    } else if (res === 'NOT_VALIDATED') {
      alert('This session has not been validated')
    }
  } catch (e) {
    alert('Failed to validate review verification')
  } finally {
    loadingVerifyMutation.value = false
  }
}
/* ENTER PHONE NO */

/* NOTIFICATION PAGE */
function handleRetryRedeem() {
  validateSpending()
}
/* NOTIFICATION PAGE */

function checkCampaignExpiry() {
  const now = new Date().toISOString()

  if (campaignExpiry.value < now) {
    notificationType.value = 'CAMPAIGN_EXPIRED'
    step.value = 4
  }
}
</script>

<style scoped>
.dot-flashing {
  position: relative;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #ff0000;
  animation: dot-flashing 1.5s infinite ease-in-out both;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #ff0000;
}
.dot-flashing::before {
  left: -15px;
  animation: dot-flashing 1.5s infinite ease-in-out both;
  animation-delay: -0.2s;
}
.dot-flashing::after {
  left: 15px;
  animation: dot-flashing 1.5s infinite ease-in-out both;
  animation-delay: 0.2s;
}

@keyframes dot-flashing {
  0% {
    background-color: #ff0000;
  }
  50% {
    background-color: rgba(152, 128, 255, 0.2);
  }
  100% {
    background-color: #ff0000;
  }
}
</style>
