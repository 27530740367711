<template>
  <div
    class="flex flex-col items-center justify-center"
    style="padding: 1.5rem; background-color: white; border-radius: 6px"
  >
    <FlexRow class="justify-center mb-20 mt-20" v-if="notification.loading">
      <FlexCol class="items-center">
        <Spinner :width="16" :height="16" />
        <p class="text-sm mt-5">Loading...</p>
      </FlexCol>
    </FlexRow>

    <div v-else>
      <FlexRow class="justify-center">
        <FlexCol class="items-center container">
          <div class="text-center">
            <div class="flex flex-col items-center" style="padding: 1rem">
              <IconFrown class="w-20 h-20" />
              <div class="card-body flex flex-col items-center content-center">
                <h1
                  class="mt-2"
                  style="font-size: 35px; font-weight: bold"
                  v-html="popupTitle"
                ></h1>
                <p
                  class="mb-2"
                  v-html="popupSubtitle"
                  style="font-size: 20px; font-weight: bold"
                ></p>
                <p v-html="popupBody" style="font-size: 20px"></p>
              </div>
            </div>
          </div>
        </FlexCol>
      </FlexRow>

      <FlexRow class="w-full">
        <button class="btn btn-mulah w-full px-5 py-3" @click="handleClose">Back To Home</button>
      </FlexRow>
    </div>
  </div>
</template>

<script setup>
import FlexCol from '@/components/layout/FlexCol.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import IconFrown from '@/components/icons/IconFrown.vue'
import { computed, ref } from 'vue'
import { useBrandStore, useNotificationConfig } from '@/stores/store'
import Spinner from '@/components/reusable/Spinner.vue'
import router from '@/router/index.js'
import { useRoute } from 'vue-router'

const store = useBrandStore()
const route = useRoute()
const notification = useNotificationConfig()

const brandSlug = ref(route.params.slug)

const props = defineProps({
  specification: {
    type: String,
    required: true
  }
})

const popupTitle = computed(
  () =>
    notification.notificationConfig.find((item) => item.specification === props.specification)
      ?.popupTitle
)

const popupSubtitle = computed(
  () =>
    notification.notificationConfig.find((item) => item.specification === props.specification)
      ?.popupSubtitle
)

const popupBody = computed(() => {
  const config = notification.notificationConfig.find(
    (item) => item.specification === props.specification
  )

  const data = {
    brand_name: store.brandName
  }
  return replacePlaceholders(config.popupBody, data)
})

function replacePlaceholders(text, data) {
  return text.replace(/{(.*?)}/g, (_, key) => data[key] || '')
}

function handleClose() {
  router.push({
    path: '/' + brandSlug.value
  })
}
</script>
